export interface Exercise {
  title: string;
  exercises: string[];
}

export type Title = keyof typeof EXERCISES;

export const EXERCISES = {
  "The Sound of A in Far": {
    title: "The Sound of A in Far",
    exercises: [
      "The blind bard. The bark of the larch. The chart is in the barge. We fish for carp. Do not carp at my farce. A cart in the park. Darn my sock. Do not gape. A harsh smart. The barb of the hook. Tar is on the spar. A pot of lard. The star shines.",
      "A large farm. Marl is a kind of clay. The yarn is in a snarl. The stocks are at par. Starch my scarf. Carve with a sharp knife. Start not. Parse that line. You shall not starve. Hear my charge. A wide path. A hot bath. Stanch the wound.",
      "Our barber is an archer. He mounts his charger. A cartridge box. A fat partridge. Market is the place for barter. My father knows a carpenter. He had a jaunty manner. Carmine is crimson. I can go no farther Garnet is a gem. The time of harvest. The margin of the lake. A big parsnip. My partner in trade. Smell of the hartshorn. Starboard is the right-hand side of a ship; larboard, the left.",
      "A scarlet dress. A starveling dog. Tartar is sour. The varlet had a cigar. Alarm the city. Unbar the door. Let him depart. Do not debar his coaling. Disarm him. Let us embark. Discharge the prisoner. It will retard my going. What did you remark? Discard evil. A barberry bush. Quince marmalade. A martingal for the horse.",
      "My dauntless aunt took a jaunt to see a launch. Do not taunt her. A gaunt form. Guard the haunch. A haunt for robbers. They run the gauntlet. A staunch friend. The sergeant is hearty. Where is Taunton? Sit on the hearth, and hearken. Yellow with the jaundice. She flaunts in a gay dress. Let us saunter along. To vaunt is to brag. Avaunt ye triflers! Dogs craunch bones. Go to the laundry."
    ]
  },
  "Short A, as in Hat": {
    title: "Short A, as in Hat",
    exercises: [
      "The lofty Alps. He bade me wear a badge. A bland smile. A blank book. The bran of wheat. Brand the scamp. Strike a match. I forbade the bans. Latch the door. Pay cash. The colt champs the bit. An iron lamp. Clank the chain. A crank ship. The flange of a wheel. The lapse of time. He hath a hank of drab thread. A manse near the mall.",
      "A plank road. A quack doctor. An Indian scalp. A scant scrap. A big patch. Shall we catch shad? Thatch the roof. A snag in the river. A postage-stamp. A tract of land. Thrash the grain. Twang went the bow! Talc is a kind of earth. Cats scratch.",
      "An acrid taste. An arrant fib. Behind the arras. Arid ground. The aspen-leaf. The vote by ballot. A barrel of brandy in the cabin. An oat-meal bannock. Birds carol. Mushroom catchup. A patent right. The gastric juice. A matin song. Throw this javelin. Tarry not. The tassel of a cap. The elective franchise. A radish for the salad. The scaffold fell. Lather his chin. I would rather gather apples.",
      "Poll parrot. Claret wine. Cod and haddock. He slept in a hammock. Hear this maxim. A transcript of the news. A shallop on the water. Cry havoc! A silver salver. Hard to clamber. A classic writer. The bones of a mammoth. The priest's cassock. See him gallop. The scale of the gamut.",
      "The attack was with a ratan. The Erie canal. Decant the wine. A mishap in the morass. You can not abash him. Come, dispatch! You distract me. What part do you enact? A sad romance. Never infract a promise. Tie your cravat. A cabal against me. The expanse of waters. I fear a relapse. Relax your hold. The minister of finance. The enemy decamp. Refract the rays. Detach the vine from the tree.",
      "Arable land. Make an asterisk. Pick a cranberry. The galaxy or milky way. A caravan of animals. Clarify the oil. A gaseous smell. A foaming cataract. A fabulous tale. A ripe apricot. Sassafras root. Faded tapestry. She stood on the parapet. Read my manuscript. The yellow marigold. You magnify bis merits. Hard as adamant. A plaid vest. Do not heed her raillery."
    ]
  },
  "Long A, as in Fate": {
    title: "Long A, as in Fate",
    exercises: [
      "A strange change. Baste the hem. Cows graze. Boys skate and wade. Do not scrape the slate. Slake thy thirst. Grace is in all her steps. A turning-lathe. A chaste style. I crave pardon. You are a craven. Hit the snake with the spade. Chafe the nape of his neck. Make a cage. A grange is a farm-house. A range of hills. Sour grapes. She quakes with fear. Brace the drum. Scale the hills. He ate a cake. The grade of the street.",
      "The stranger is a trader. The dog in the manger. A famous patron. The matron has a cambric robe. Put a label on the paper. The angel Gabriel. The apex of the hill. Pastry makes me ill. A poor changeling. Give not way to hatred. A scraper for the feet. Wafer the letter. Shun the gamester. The sacred book. Frustrate his plans. By cognate we mean akin. The inmate of the hut is an ingrate. A fine landscape. An ornate style. Vacate my room. This mundane home. Do not strike the prostrate. A radix is a root.",
      "Forsake evil. Escape danger. A brocade dress. Do not estrange us Erase my name. We inhale air. Impale not the butterfly. The cascade falls. Arrange her dress. A sedate mood. What a grimace! Do not derange my books. You inflame her temper.",
      "Conjugate the verb. Aspirate the ‘wh’ in ‘what’, ‘when’, &c. Celebrate the day. The delegate is a candidate. Separate the boys. Designate the reprobate. Ventilate the school-room. To emigrate is to go from a land — to immigrate is to go into it. Excavate the hill. Masticate thy food. Navigate the ship.",
      "Inculcate good thoughts. To demonstrate is to prove. Remonstrate with her. Sip the lemonade. The men set up a barricade. Cling to the balustrade. I stood on the esplanade. Mary had a serenade. Beware the ambuscade. Form a palisade. The pillars of the colonnade.",
      "Never prevaricate. I penetrate his plan. Wine intoxicates. Do not retaliate. It blew a hurricane. Investigate the case. Incarcerate the culprit. You cannot intimidate me. I will interpolate a sentence.",
      "Aid the maid to braid the chain. Never break faith. The snail leaves a trail. A quaint sketch. A tender steak. Fail not to gauge the cask. A bad sprain. Free from taint. A train of cars. Frail as glass. In jail. We lire twain. They gave him great praise. Take out the stain. He hit me with a flail.",
      "The caitiff wore gaiters. The sailor was no traitor. My raiment for a rainy day. Milk from the dairy. The bailiff is with the plaintiff. Our daily bread. The daisy has its name from day's eye.",
      "Abstain while you can. He is afraid to declaim. I am under constraint I refrain. I disdain to beg. I maintain it. We bewail her fete.",
      "The appraiser is the complainant. Ascertain who was the assailant. His estate is under attainder. The remainder appertains to me.",
      "Do not stray out of the way to-day. Pay as you go. For ever and aye. We make bricks of clay. A blue jay on the spray. Gray hair. Hurt in a fray. Pray stay. He holds sway.",
      "We gayly play. They went to waylay the mayor. A layman spoke. Assay the gold. Delay your gayety. Here is an affray. A wayward temper. A crayon likeness.",
      "Eight skeins of silk. Weigh them. A heinous crime. A weigher and gauger. Why inveigh against him? A heavy freight. Weighty cause. The eighth day. Will you deign to obey me? Wine whey. Eagles convey their prey to their eyry. Horses neigh. Rein them in. Truth must prevail. A  neighbor of mine. The sleigh was upset. The queen's reign. Upbraid the wicked."
    ]
  },
  "The Sound of A in Fall": {
    title: "The Sound of A in Fall",
    exercises: [
      "I want a quart of warm water. A small, bald dwarf. All waltz at the ball in the hall. A horse in the stall. A swarm of bees. The green sward. A ward of the city. The covers warp. A wart on my hand. A squall of wind. A swart face. Bitter as gall. Thwart the false boy. Salt hay. Malt liquor. Halt there! Scald the pan. A ship at the wharf.",
      "An alder tree. Hear me also. Always busy. Birds warble. A halter for the palfrey. A paltry wardrobe. With cobalt we color glass. Stop your warfare. A swarthy skin. Smell of the balsam. A big quarto. Held in thraldom. Never falter. She shakes with the palsy. A warden is a keeper. The jackal ran. I warrant the watch.",
      "A just award. Exalt the lowly. A pillar of basalt. Athwart my path. Recall thy words. Install him into office. Her song inthralls me. You are wise withal. The alderman has an almanac. A subaltern in the army. The city of Chicago. Let us talk as we walk. He is a calker. The falconer made the falcon fly. A bit of chalk.",
      "Pause on the lawn to see the fawn. A gauze shawl. The yawl sank. A fat paunch. Shun a brawl. He fed on brawn. He caught a hawk by the claw. Rooks caw. A clause in a will. Do not drawl. A cold vault. The spawn offish. An Indian squaw. Who taught you to sprawl? Sauce for the goose. Raw meat. Laud the good. A heap of straw. The ice thaws. Fraught with danger. Whose fault? A flaw in the gem. Do not maul the lad. He goes to pawn his law-books.",
      "Auburn hair. Goods at auction. The month of August. A faucet for the cask. Be not haughty, my daughter. The hawthorn bush. Audit my account. An awkward author. A caustic remark. He spoke at a caucus. The pauper had a sausage. Cross the causeway. A laurel tree. A mawkish style. Naughty boys. Do not falter. Who ever saw a centaur. An awful slaughter. Hear the plaudits! A sawyer in the river. A saucy girl. She broke a saucer. Put it in the drawer. My drawers are torn. I am distraught with woe.",
      "The bashaw has a macaw. To debauch is to corrupt. Never defraud. A wicked assault. He was audible to the audience. A good augury. A nautical song. A paucity of good fruit. Laudanum is made from opium. A plausible story. Cauliflower for dinner. The marauders stole a sheep. An Indian tomahawk. Shun the defaulter. A hydraulic ram. Nail down the tarpaulin. The aeronaut is to go up in a balloon.",
      "He brought me a fork. I thought him a lord. He is gone north. A broad plank. He lit a torch. An acorn fell. A snow-storm. I bought a book. Pigs snort. Morn and eve. The dogs fought Scorn a lie. A narrow gorge. Gorse grows there. A bad cough.",
      "A border of box. Live in concord. Cordage for the ship. Sound the cornet. The sergeant wore a corselet. His wife wore corsets. Snakes lie dormant and torpid in winter. A formal demand. Two weeks are a fortnight. I took out the thorn with a pair of forceps. A peach orchard. A strong fortress. Move forward. A normal school. Bricks and mortar. A morsel of cake. A sad discord. Sip the porridge. The porpoise is a clumsy fish. A hornet stung her. Lost in a vortex.",
      "A forlorn hope. He will endorse the note. Assort the beads. Extort his consent. I besought him to reform. He felt no remorse. I exhort you to resort there no more. A sponge absorbs water. Abhor evil. Conform to the good. The bad glass distorts. They tried to suborn the witness. What did he retort?",
      "A corpulent man. A scorpion stung. A porcupine quill. A tortuous road. A porcelain cup. The wind is northerly. A hortative remark. The orthodox faith. A porringer of milk. Exorcise the evil spirit. He would be a sorcerer. An abortive task. Caloric is heat. The immortal soul. What is her disorder? Important news. Meteoric flashes."
    ]
  },
  "The Sound of Long A in Care": {
    title: "The Sound of Long A in Care",
    exercises: [
      "An asp bit her arm. A glance in the glass. Quaff from the cask. I set the lass a task. She was lying in a trance. The haft has a clasp. Rasp the slate. By chance I hit her with a lance. The branch fell on the grass. The chaff flew before the blast. Slant the shaft more. Put down the flask. May fair winds waft on the ship! See the horse prance.",
      "Plaster fell from the rafter. A ship chandler. My repast is in a basket. A jasper ring in a casket. The mastiff bites. Answer his slander. A passive verb. She looked askance at me. He will enhance his price. An alabaster vase. A salamander safe. The advantage of a good example. Why amass more? Music enchants me. The pilaster fell. A hogshead of molasses."
    ]
  },
  "Long E, as in Be": {
    title: "Long E, as in Be",
    exercises: [
      "Cede the glebe to him. We can eke out a meal. A breve is a note in music. Here is my theme. A sere leaf. Mere nonsense. A concrete mass. Lift the lever. Smell the ether. The petrel flies. The king's edict. Obese is fat. The temper of a demon. Keep my secret. The college precincts.",
      "An austere reproof. The parts cohere. Be sincere. Extreme cold. Dogs secrete food. A complete suit. Accede to my request. Adhere to the good. The penult is the last syllable but one. Precede us. Convene the scholars. You impede the road. The supreme good. Replete with joy. An obsolete custom. A period, or full stop. The stevedore loads the ship. Persevere in well-doing. Intercede to make peace. Contravene his attempt. The new book will supersede the old. Here the noun antecedes the verb. Plant the heliotrope.",
      "Glass beads. A bird's beak. Bleach the cloth. A crease in my vest. The eaves of the house. A bleak wind. A cheap peach. A long beard. Blear-eyed. Lambs bleat. They cease to breathe. A deal of cream. A neap tide. A fleam is for bleeding beasts. He nailed on a cleat. Each ear. Lead us on. Leap year. Heal the sick. A high peak. Shell the peas. Cleave the wood. Put in a plea for me.",
      "Reach me the shears. A sheaf of wheat. Sheathe the blade. The sheath is old. Yeast for the cake. A ream of paper. Read the book. Smear the tree. Speak louder. A veal pie. Pigs squeal. A streak in the east. The common weal. Wean the babe. He is no sneak. Take your seat. The stream flows. What is teak? Teal?",
      "The beacon light. The beaver dam. Deacon Smith. A dreary road. Eager to play. An easy chair. Her hands are greasy. The seamstress is weary. She is meager, too. A treatise on corns. Treacle is sweet. A yearling is one year old.",
      "Beneath a tree. Anneal the glass. Appeal to him. Appease his rage. You bereave me of hope. Bohea tea. When did his decease take place? What was his disease? Water congeals. Impeach the witness. It is not feasible. His claim is indefeasible. Repeal the act. The foe retreat. Release the captive. His account is in arrear.",
      "Roast beef. A sweet cheese. A breed of sheep. What cheer? The breeze veers. Steer the ship far from the reef. A fleet steed. Sweep the street. Squeeze him and he will screech. A queer sleeve. Slow of speech. The vessel's keel. A sleek fleece. Feed the eel. The angler's reel. Pull up the weed. A green tree. A wet sheet. The earth reeks. Screen him from sight. The lees of wine.",
      "Linseed oil. The steeple was blown over. A fleecy lamb. A peevish girl. Tweezers to pull out a thorn. A keepsake for you. Thread the needle. Do not tread on the beetle. A greedy child. It is agreed between us. A tureen of soup. Be discreet. A full canteen. Careen the boat. The career of the lessee was short. A genteel dress. We sat on the settee. Veneer the box. He steps like a grandee. Moreen curtains. Razee the ship. Light the fusee. One of the trustees of the school.",
      "We call our rooster Chanticleer. A day of jubilee. Filagree work. A wilt's pedigree. An absentee from home. An auctioneer's sale. The engineer tries to domineer. Is the name of our town in the gazetteer? A muleteer drives a mule. A western pioneer. The overseer of an estate. A smart repartee. The mutineer was shot. A Polish refugee. A volunteer in the army. A fricassee of chicken. The people fled.",
      "Seize the weird woman. He can touch the ceiling. Either you try to deceive, or do not perceive the truth. A seizure of rum. A moment of leisure. I owe you no obeisance. Receipt my bill. Your conceit makes me smile. They could not inveigle him to do wrong.",
      "A brief ride. The chief is in the field. Raise the siege. We made the thief yield. He had the look of a fiend. My little niece. Shield her from harm. A fierce strife. His land is held in fief. A tierce of beef. Pierce the bag. My liege, I cannot. A series of losses. A prairie on fire.",
      "The cashier of a bank. Indians on the frontier. Do you believe he will have a reprieve? He will yet retrieve his fame, and achieve great things. The enemy made a sortie. A brigadier general. He was armed cap-a-pie. Our cavalier is no financier. The grenadier wore a cap.",
      "My pelisse was in the valise. Report your loss to the police. A deep ravine. I am weary of her caprice. Ambergris has the smell of wax. Verdigris is green. She had a bombasin dress. A powder magazine. A ship in quarantine. A Chinese mandarin. She plays on the tambourine."
    ]
  },
  "Short E, As in Met": {
    title: "Short E, As in Met",
    exercises: [
      "Sit on the bench. He came at my beck. To blench is to shrink. The game of chess. The wood was cleft. A dense smoke. Dredge the meat. He can etch on copper. I see no one else. Fend off the attack. A ledge of rocks. Kelp is from sea-weed. Go hence! Look through the lens.",
      "Quench the fire. Go in quest of the elk. The smelt swims. A wedge of gold. A sledge hammer. He dug a trench. Weld the pieces. To vend is to sell. The tenth hour. What was the text? Twelve shells. The stress of the voice. I reck not what they say. She swept the carpet. Stretch the rope.",
      "My merry messmate was found in the belfry. He had a kettle on for a helmet. Tepid water. A fetid smell. I sent her on an errand. Trim the fetlock. A velvet dress. The selvedge of the cloth. A relic of the lost one. Mrs. B., the relict or widow of Mr. B. The prince is a despot. Send for the sheriff. The skeptic does not believe. Hemlock is poison. They hold an inquest. My nether garment. A strange tenet.",
      "Attempt not to avenge thyself. Condense your remarks. Caress the child. You allege what is not correct. The cadet has a bequest. What are his assets? Do not abet the bad. An adept in crime. What is your address?",
      "Try to excel. An immense fire. The gazette was sent by express. It seems to me like finesse. What can you expect? Exempt from blame.",
      "Dissect the duck. Inspect the ship. You depress her by your talk. Why oppress the poor? My pain is intense. A defect in his title. Do not foment discord. The member elect. Erect the tent.",
      "You perplex me by your pretext. Do not resent it. I wish you success. I possess all I want. Neglect her not. You profess too much. Refresh yourself. Protect the weak. Seek not revenge. I relent. Propel the car. Malice prepense. You transcend my hopes. Let me suggest that you keep him in suspense.",
      "The terrier barks. A retrograde movement. A querulous voice. My memory fails. Call him not a heretic. A fit of ecstasy. A mezzotint print. Recompense the messenger. A splenetic temper. The retrospect of my deeds. An authentic account. A contemner of things good. Interrogate the preceptress. A generic name. Recollect my charge. Intercept the thief.",
      "He says he has not any waistcoat. Many are as badly off. She hit her head against the wainscot. She read in his stead. I dreamt a dream. She dealt out bread to the hungry. The sweat of thy brow. He meant well. The realm of death. Cleanse thy breast. Too deaf to hear. In good health.",
      "Breakfast is ready. The peasant stood on the heather. A feather for your cap. Pleasant weather. Leaven for the bread. A zealous will. Hope in heaven. Measure the weapon. Endeavor to be cleanly. Hate all treachery. Gold in the treasury. A belt of leather. The grass of the meadow.",
      "Guess who is my guest. We will not jeopard our friendship. The leopard has spots. The miser means to bury his gold. Nonpareil type. To feoff is to invest with the fee of land. An aesthetic taste. The heifer lows. Et cetera are two Latin words, meaning ‘and so forth.’ We sometimes use this mark &c. in place of them. A diaeresis is a mark used to show the separation of syllables, as in Zoë."
    ]
  },
  "The Sound of E in Her": {
    title: "The Sound of E in Her",
    exercises: [
      "They were on the verge of ruin. Does not the clerk err? Sperm oil. Which is the verb? If thou wert pert, say so. Thou wast stern. I was erst expert in writing terse verse. Taste of the perch. Serge is a thin woolen stuff. The herbs of the field. I love the smell of fern. Swerve not to the right or left. The germ sprouts.",
      "A deep cistern. They hid in a cavern. An overt act. A person adverse to my views. The modern clergy. The shepherd stood at the postern. The iceberg melts. Divers merchants went diverge ways. A bittern has long legs. The mercer's shop. The hermit had a lantern. Crack the filbert. The pastern of a horse. A halberd is a kind of spear. Pounds sterling. They rendered a verdict of guilty! Therefore let us go. Who ever saw a mermaid?",
      "Invert the glass. A superb vase. You deserve a chiding. Disperse the crowd. I prefer to preserve this flower. You cannot coerce me. Submerge the raft. Our roads diverge. Emerge from the water. Observe that bird. Reserve your fire.",
      "A pertinent remark. Rains fertilize the ground. Controvert what he says The termagant has no mercy. A serpentine walk. Perforate the bladder. The sun is now vertical. You enervate yourself by inertness. My immersion was imperfect, but I was well wet. Interpret this riddle. My maternal aunt. The interment of the dead. The days of my adversity. Hear the sound reverberate. I shall animadvert on her conduct.",
      "A dearth of news. I heard that the earl lost a pearl. Search for it on the earth. I yearn to go home. The black hearse. I was early fond of learning. Be in earnest. Rehearse your part. A man of research.",
      "Birds chirp. A birch tree. A dirge for the departed. First, throw away the dirk. A quirk of the law. The skirt is too long. A whirl of dust. Do not stir, sir. The flirt smirks. The coal will smirch thy hand. A firkin of butter. An irksome skirmish. Hold the stirrup. The squirrel has a nut. Thirty virgins. A circular to the trade. From the zenith to the nadir. A hirsute chin.",
      "Step aside from the worm. A scourge for the liar! What is his word worth? What can be worse? A tiresome journey. Our sojourn in the world. A worthy workman. Family worship. An attorney at law. Adjourn the case. Cultivate courtesy. Make a courtesy. Let us keep a journal.",
      "The cur barks. Myrrh is bitter. The churl durst not hurt me. Churn the cream. The nurse has gone to church. The surge rises. See the white surf. Surd is deaf. The bubble burst. My purse is empty. Do not slur over your lesson.",
      "Turn aside for the man with the burden. A burly burglar broke into our house. I can go no further. A purser in the navy. The curfew tolls. Pull up the burdock. A surname is a family name. The suburbs of the city. We had turnips on Thursday. The martyr was burnt. A murky sky. A surcingle for the horse. The myrtle grows. The planet Saturn. We fish for gurnet. The guerdon of all my toil. The Satyr of the fable What is your purpose?",
      "I concur in your views. I demur to your demand. Unfurl the sail. Disburse the money. Read the liturgy. Put turpentine on the sore. I am past surgery. The turbulent boys disturb me. A suburban cottage. Diurnal is daily. The emperor is a usurper. Reimburse the servant. This shed is appurtenant to my house."
    ]
  },
  "The Sound of I in Fit": {
    title: "The Sound of I in Fit",
    exercises: [
      "Bilge water has a bad smell. The bridge was swept away. A chintz cover. Crimp my ruff. Cringe to no one. Didst thou fall into the ditch? The disk of the sun. A film over his eye. A flitch of bacon. A glimpse of sunshine. A milch cow. A niche for the vase. The plinth of a pillar. Mince pie. In the midst of the crowd.",
      "Rinse the cup. A rick of hay. The skiff skims the lake. A stitch in time. A springe is a snare. The thill of a wagon. A whiff of smoke. The wick of the candle. The width of the street. A wisp of straw. I felt a twinge. He hit him with a withe. He is not a whit too good. Our sink is lined with zinc. The witch ran. Which witch? There is no such thing.",
      "Our bishop is no bigot. The district school. Do not injure the insect. The linch-pin broke. A ripe pippin. I ate a sandwich. The scrivener writes. Show some spirit, sirrah! The witness wore a visor. He found the victim in a vineyard. They went thither. A tiny pitcher, full of sirup. A spigot in a cask. A timid vicar. The livelong day. The limner draws. The index turns on a pivot.",
      "Abridge your visit. Will they acquit him? Desist from strife. Forget and forgive. The moon’s eclipse. The form of an ellipse. To impinge is to fall against. Do not infringe the law. I predict a storm. The prince will rescind the decree. You gave me cause for chagrin.",
      "Chastisement for the guilty. I commend your industry. The milliner waits. In the interim I will see the minister. A rickety chair. A virulent poison. The imagery of the poem is fine. An insipid elixir. He is an empiric, but no quack. Statistics of the census. Touch the violin.",
      "The captain and the chaplain came in a carriage to the marriage. Draw the curtain. Sit by the fountain. The villain ran. A miniature likeness. Parliament sits in England. A mullein leaf. Pretty women. A mischievous boy in breeches. A sieve for the meal. You shall forfeit your kerchief.",
      "They build a house. A crypt is a sort of cave. A myth is a fable. The calyx of a flower. Be busy. A conduit for water. Put down the ferule. A head of lettuce. Syringe the plants. Wait a minute. Tune the guitar. Tortoise shell. I gave the poor man a guinea. A bunch of hyssop. A lyric poem.",
      "An amethyst cup. A stove-pipe is in the form of a cylinder. We inhale oxygen. A sycamore tree. The pyramids of Egypt. Tell me what you mean by a syllable. Never play the hypocrite. A synonym is a word having the same meaning as another word. An attack of dysentery. She has the dyspepsy. She must try gymnastic exercise. On me when dunces are satiric, I take it for a panegyric. Polygamy was common in Utah. An idiosyncrasy or peculiarity, of her constitution."
    ]
  },
  "The Sound of I in Fine": {
    title: "The Sound of I in Fine",
    exercises: [
      "A gibe is a taunt. The snipe flew. A trite saying. The rind of a melon. The squire is the chief man of the shire. You rive my heart. Let me splice it. A tithe is a tenth. Whilst the sun shines the birds are blithe. Might does not make right The wight was in a sad plight. A blight is on the rose. Sigh not. They took to flight in affright. Drive a spile into the cask.",
      "A biped has two feet. A bivalve has two valves. A feline tread. A contrite spirit. Such is the fiat of the king. Turn the grindstone. The Jew and the Gentile. A lilac bush. A spiral wire. A spinal complaint. Byron wrote a satire. Taste the viands. The cider has a vinous flavor.",
      "He will baptize the child. The coal will not ignite. Describe the scene. To opine is to think. Canine madness. Saline springs. You will survive your surprise. Let that suffice. I do not oblige you to subscribe. He by supine. Let her lie down, she will revive. Let her lie there a while. How long did she lie there? She lay there an hour. A pillow was laid under her head.",
      "Alibi is elsewhere. To prove an alibi, is to prove that you were not in a certain place. Draw a diagram. Look through the microscope. A sinecure is an office without care. Aconite is poison! O! the sweet Spring violets! Anthracite coal. Alas! the parricide! A pantomime is mute mimicry. Put turpentine on the sore. A heavy sacrifice. An elegiac poem. Stalactites hung from the cavern.",
      "To dogmatize is to assert confidently. Do not gormandize. Tranquilize your fears. Scrutinize his conduct. Harmonize our plans. Pulverize the stone. Moralize on life. I cannot realize that you are married. The drops will crystallize. He tries to aggrandize his family. I did not recognize him. Let death solemnize our thoughts. You tantalize me. Will you authorize me to try it? They will canonize the saint.",
      "Advertise your loss. Compromise the matter. Criticise my style. A bold enterprise. A room full of merchandise. You must apologize. Hear Hamlet soliloquize. Italicize the word. Do not monopolize the food. She means to be electrized.",
      "The subsidence of the waters. The arrival of the cars. An aspirant to office. One of your admirers. He seeks an alliance. What shall be the requital? Good society. A variety of things. Do you coincide with me? Environ him with friends. The environs of the city. Below the horizon.",
      "The height of the tree. Sleight of hand. The eider duck. He plies his trade. I was the buyer of that scythe. A funeral pyre. A cypress tree. Go to the dyer for my dress. A blind guide. He is free from guile. My right eye. He wore a disguise. The ally of France.",
      "Hyson tea. Hydrogen gas. An anodyne soothes pain. Analyze the powder. Do not look awry. A prototype or model. They go to occupy the land. He will ratify the bargain. ‘Ay,’ meaning ‘yes,’ rhymes nearly with ‘my;’ but ‘aye,’ meaning ‘always,’ rhymes with ‘day’."
    ]
  },
  "The Sound of O in Go": {
    title: "The Sound of O in Go",
    exercises: [
      "Force the bolt. A bone for the dog. A torn flag was borne to the fort. The yolk of an egg. A shorn lamb. Unroll the scroll. The dolt trolls a song. I am loth to use the sword. The whole knoll is sold. He quotes prose. He can pose them both. Forge the steel. Put on thy clothes. No more sloth. The joke was gross, not droll.",
      "I sat in an alcove. A bolster for the bed. Compost for the plants. A homely profile. Molten gold. The dotard is froward. An overt act. Our grocer plays on the trombone. An onyx ring. A fine opal. Read the programme. Only a locust. Oral advice. Add a postscript.",
      "Postpone your jocose remarks. Control your morose temper. You cannot cajole me. Condole with the sad. Support the weak. To ignore a thing is to declare ignorance of it. A verbose report. The patrol were out all night. The prisoner is out on parole. Do not engross all the room. Portray the scene.",
      "From the portico I went to the corridor. My bane and antidote. A misanthrope is a man-hater. He told an anecdote. It was an episode in his speech. The antelope ran. The oriole flew. A decorous proposal. A visit of condolence. A heroic opponent. The locomotive is off the track. Gum ammoniac.",
      "Give to ‘oa’ the full sound of long ‘o’ in roam, boat, cloak, coat, goat, road, toad, and throat. Broach the subject. The boys coast down hill. Soak the oats. Woad is a plant. Shoal water. The goal of my hopes. Poach the egg. A boatswain hoary with age. Taste of the cocoa. An uproar on the railroad. The boat is afloat. Do not encroach on my land.",
      "A bourn is a bound. The gourd grows. A mould for a bullet. Birds moult their feathers. A throe of pain. War brings woe. The door fell on the floor. Aloes are bitter. A concourse of people. Poultry is dear. The yeoman shot the foeman. A poultice for his shoulder. Our intercourse was brief. The colter or fore-iron of a plow.",
      "A slow growth, I trow. Mow the grass. Row the boat. Strew flowers. Sew the shirt. A brooch of gold. A bowlder is a round mass of stone. A ship has a bowsprit. Lo! the rainbow! Winnow the corn. The minnow swims.",
      "The arrow fell wide of the mark. The shadow of the willow. A shallow furrow. Tell the fellow to follow. A widow sallow with sorrow. The field lies fallow. A callow bird. Hallow the day. Harrow the land. A narrow plank. A pillow for the head. A whitlow on her finger. Six pairs of bellows. The gallows stands. The gallowses will be thrown down.",
      "The beau struts. Put the dough in the oven. My bureau drawer. A quarto volume. Do. stands for ditto. A cargo of guano. A halo round the moon. The bravo escaped. The President's veto. She sang a solo. He paints in fresco. He caught a horse with a lasso. Calico gowns. Cameo pins. Blue as indigo. Enter my studio. She was seized with a vertigo.",
      "What a farrago! Keep this as a memento. Merino sheep. A morocco box. An embargo on commerce. A ripe tomato. A tornado swept over the town. She is a virago. Give the desperado the bastinado. Hear the rebel's manifesto. The curculio has hurt our trees. Muscovado sugar."
    ]
  },
  "The Sound of O in Not": {
    title: "The Sound of O in Not",
    exercises: [
      "A bronze lamp. Floss silk. A fosse or ditch. A copse or wood of small trees. Mutton chops for dinner. A pair of tongs. A strong thong. They throng to the spot. The dross of metals. A lodge in the wood. A moth in the candle. Notch the shingle. She is no romp. Scoff not.",
      "They bother the cobbler. Grind the coffee. A cobweb in the closet. A sorrel horse. There is crock on her forehead. In my progress I came in contact with a robber. Congress sits. Solder the pan. A proper object. My comrade wrote a sonnet. A doubtful combat. He put the lobster in his pocket. A frolic in the hovel yonder.",
      "He hoped to abscond. Accost him. Anon, I will. Emboss the shield. I forgot to extol his conduct. To whom will they betroth her? Go across the street. I revolt at the thought. Revolve it in your mind. Your labor devolves on me.",
      "He shot an albatross. A ripe apricot. A comatose state. The day of Pentecost. A green parasol. A dolorous ditty. Commodore Hull. A hollyhock in bloom. A nondescript flower. The bones of a mastodon. The frontispiece to a book. He spoke in a monotone. An aliquot part.",
      "Watch the yacht. A swan in the swamp. The chaps of a beast. They seem chapfallen. A swath in the grass. A big squash. Thou wast present, if thou wert not, say so. Beware of the wasp.",
      "The squadron sailed. A warrant for your arrest. Do not wallow in the dirt. Wanton mischief. A swallow’s nest. A squalid aspect. The wassail cup. Fresh halibut. Knowledge may be had at college. Quadruple the amount. The quadrature of the circle. How many feet has a quadruped?"
    ]
  },
  "The Sound of OO in Book": {
    title: "The Sound of OO in Book",
    exercises: [
      "By hook or by crook. He stood by the brook and shook a stick at me. Woolen cloth. A woolly sheep. You could not if you would, and should not if you could. Drive away the wolf.",
      "The bully lay in ambush to attack the butcher. Hark to the cuckoo! A woman in the pulpit. Bullion is gold or silver before it is made into money. A bird on the bulrush. Put a cushion under my head. The emperor issued a bulletin. A bushel of meal. A pullet's egg."
    ]
  },
  "The Sound of OO in Cool": {
    title: "The Sound of OO in Cool",
    exercises: [
      "Hear the wind boom. I have a boon to ask. The boor sat in a booth. Choose a broom. Shoot a coot. A groove for the wheel. Throw a noose over the moose. We trill moot that question. A rood is the fourth part of an acre. A bag of soot. In sooth I could not soothe her. Hens roost.",
      "The booby ran off with the booty. I stood aloof while the balloon rose. The buffoon wrote a lampoon. The poltroon shot a raccoon. A platoon of troops. Unmoor the skiff. A cook in a caboose. It behooves you to heed my reproof. Mend the pantaloons. A picaroon or pirate.",
      "Crude food. Water rushes down the flume. The fluke of an anchor. A spruce prude. A truce to your jests! A bluish tint. We call the bear ‘Bruin,’ from a French word meaning ‘brown’. A prudent ruler. A fluent truant. A ruby is red. He has no scruples. An abstruse subject. She is a brunette. Salute her. He wore a peruke. The days of the crusades.",
      "Melt it in the crucible. A rubicund cheek. The gift of fluency. Teach her the rudiments. The lunatic sang. A luminous cloud. I like not his scrutiny. A truculent style. A ruinous fire. A man of influence.",
      "Do not lose your shoe. What route shall we choose? Prove all things. Do not wound the youth. He had the croup two days. The wife of his bosom. Much ado. The contour of her face. An uncouth surtout. On my tour I was wet through in a canoe. The routine of business. Improve thyself. A cruse of oil.",
      "A shrewd shrew. No clew to his fate. In lieu of a brewery, you shall build a school-house. Is she ill with the pleurisy, or the rheumatism? Bruise the fruit. A long cruise. Construe that line. Recruit your strength. Good must accrue from it."
    ]
  },
  "The Sound of Short U as in Tub": {
    title: "The Sound of Short U as in Tub",
    exercises: [
      "A bluff, blunt, plump judge. He will not budge. Courage he calls pluck. Ill with the mumps. Thieves skulk. Soap suds. Mulct him in a fine. Plunge in. What lungs! A duct for water. A fresh bulb. He had not swum far when he sank.",
      "Pass the buckets. The usher made a blunder. Fulsome praise. Let our bumpers be of cold water. A crupper for the horse. Dulcet strains. A sumpter mule. My grocer charges me with sundries. The gunwale of a boat. A puncheon of rum. Tap it in the gutter. Come to luncheon. Hear my summons. A stubborn mule. A woodchuck in a hole. A cudgel for the bad.",
      "An abrupt manner. Expunge that line. Divulge thy secret. A robust youth. An adult, or grown-up person. To succumb is to yield. Rotund is round. Occult is hidden. Do not interrupt me. The occiput, or hinder part of the head. A difficult task. The autumnal season. An impulsive temper. An odd occurrence. Rebut his charge.",
      "A front seat. A kid glove. Hold thy tongue. Once a month. A wet sponge. None will come. Dost thou know it? Mere bombast my brother! An uncomely sloven. The points of the compass. A boiled onion. The pommel of the saddle. A dozen plovers. Govern thy temper. She does nothing on Monday. Covet not. Birds flew to their coverts. A mongrel goose. A thorough flogging. Shovel the snow.",
      "A wonderful somerset. A coverlet for a bed. The constable arrested the conjurer. Confront your foe. Perils encompass, but do not discomfit him. What effrontery! A colander for the jelly. Go amongst friends. The hen was tough enough. A cousin in the country. Floods of blood. Two lines that rhyme are a couplet. The rough slough of a snake. My doublet cost a doubloon. The young chough flew. Long may you flourish! Food will nourish her."
    ]
  },
  "The Sound of U in Mute": {
    title: "The Sound of U in Mute",
    exercises: [
      "A cube has six equal sides. The die with which we play backgammon is a cube. The plural of die is dice. A dupe of a duke. A tube is hollow. Play a tune. Do thy duty. A humid soil. The students make a tumult Put them in durance. A tumid style. Statute law. The tribune of the people. Hark to the prelude! The length of a cubit.",
      "An astute reasoner. A demure manner. May I presume to rebuke her? Blushes suffuse his cheek. To denude is to strip. Traduce him not. Compute the amount. An acute lawyer. Educe good from evil. Haul in the manure. The tree exudes gum. An obtuse understanding. A profuse sweat.",
      "Wear this amulet. An aperture or opening. A ripe cucumber. A century hence. An Indian calumet. A manuscript is writing done by the hand. The cuticle or outermost skin. Mucilage is slimy. A furniture warehouse. Disdain a subterfuge. She lost her reticule. Act in unison. The vestibule or entrance. To manumit is to free. A premature growth. An opportune visit. An annuity, or yearly payment. Importune her not Introduce me. A voluble lady.",
      "An accurate account. Conjugate the verb. Amputate the arm. Fortunate man! You stimulate my hopes. An obdurate heart. Emulate his worth. He insulates himself. An immaculate reputation. Nothing extenuate. Never dissimulate. An importunate beggar. She perambulates the streets. Inoculate the child. The foe will capitulate. Repudiate nil envy.",
      "A popular speaker. An annular form is that of a ring. Tabular refers to a table; circular, to a circle; granular, to a grain; insular, to an island; titular, to a title; jocular, to mirth; tubular, to a tube; globular, to a globe; consular, to a consul; secular, to the world or age; vehicular, to a vehicle; oracular, to an oracle; reticular, to a net; vesicular, to a vesicle. Our vernacular tongue. A particular person. A perpendicular wall.",
      "A punctual scholar. A virtual contract. A casual gain. The ritual of the church. An eventual loss. An impudent argument. He grows corpulent. Succulent is juicy. A fraudulent document. Large emoluments. An integument or covering. Feculent odors.",
      "The latitude and longitude of our town. An aptitude for study. Extreme lassitude. He struck an attitude. Adhere to rectitude. The disquietude of the community. The Beatitudes. Shall he harm us with impunity? Has he an immunity from blame? The vicissitudes of life. Retributive justice. The constitution of the country. The altitude of the hill.",
      "A sinuous stream. A venturous hunter. An impetuous youth. My brother is not only an ingenious workman, but he has an ingenuous or frank disposition. A circuitous route. Deciduous trees. Spurious coin. Posthumous fame. Perspicuous statements. Gratuitous insults. Assiduous in study. Ambiguous remarks. Contiguous is near. Sedulous efforts.",
      "He wore a cue. They will argue the case on Tuesday. Wait the issue. Subdue your rage. Continue to love virtue. A long retinue. An ample revenue. Beautiful statues. Pewter mugs. A review of troops. A European war. Let us be neutral. An eloquent eulogy. Feudal times The renewal of the feud. A skewer for the cook. Stoop not to maneuver."
    ]
  },
  "The Sound of OI in Voice": {
    title: "The Sound of OI in Voice",
    exercises: [
      "A can of oil. A game of quoits. Do not roil the wine. A tender loin of beef. A coil of ropes. A coif, or sort of cap. Not worth a doit. Hoist the joist. Foil his tricks. Hurt in the groin.",
      "Noisome ointments. A sirloin of beef. Loiter not. Parboil the meat. Wipe your hands with a doily. She can broider. An invoice of goods. Shut up in a cloister. She plays the hoiden.",
      "An adroit exploit. Recoil from a lie. Boisterous mirth. A moiety is a half. Pen a rejoinder. The scales are in equipoise. Anoint the skin. Enjoin peace.",
      "The boy swam to the buoy. Sweets cloy. A hoy is a sort of boat. We had a fleet as a convoy on our voyage. A viceroy is in place of a king. A corduroy road. A dish of oysters. Gold without alloy."
    ]
  },
  "The Sound of OU in House": {
    title: "The Sound of OU in House",
    exercises: [
      "Scour the pan. The hound found a grouse. Why does the lout lounge? They will oust him from his place. A time of drought. Some persons say ‘drouth.’ Souse the meat. Scouts are out. A spouse is a husband or wife. Seeds sprout. Our cat has a mouse in her mouth.",
      "The banks will not discount my note. Is it for a thousand dollars? The amount is about ten. A dish of sourcrout. The outlaw fled. A doughty foe. A frouzy head of hair. A gouty foot. I caught a flounder. A large outlay.",
      "A profound student. Expound the law. Arouse the sleepers. A devout woman. Enounce the word. It will redound to her credit. Vouchsafe your attention. She will espouse Mr. B. The troops dismount. They will carouse. I renounce their company.",
      "A counterpane for the bed. An encounter with a mountebank. The boundary line. Health is of paramount importance. A type foundery.",
      "An acoustic tube. Plow the ground. To mow is to make mouths; an ape is said to mow. A row is a riotous noise. A mud scow. Cows browse. The wolf prowls.",
      "A fish chowder. Our townsman is no coward. A trowel for mortar. A pair of dowlas trowsers. A powder-horn. A pain in the bowels. The wife's dowry. A rowel is the point of a spur. The clouds lower. I admire his prowess. A coarse towel."
    ]
  },
  "A in Unaccented Syllables": {
    title: "A in Unaccented Syllables",
    exercises: [
      "An ultra dogma. Delta is the Greek letter Δ. An extra supply Senna and manna. A stanza of the poem. Mica shines. A vista through which we can see his villa. An area is any plain surface.",
      "An arena for the combat. Put a cedilla under the c. The corolla of a flower. In a dilemma. A doctor’s diploma. Erratum is the singular of errata. I found an erratum in the book. We found some errata.",
      "Effluvium is the singular of effluvia. Bad effluvia come from those houses. A bad effluvium comes from the sink. One candelabrum. Ten candelabra. The insignia of his office were displayed. They subpoenaed the witnesses.",
      "A tractable child. A portable stove. Read the parable. Be more affable. A palpable untruth. Taxable land. Tenable property. Potable wine. A probable story. Amenable to the law. An ineffable vision.",
      "Malleable iron. He is inimitable. A memorable day. They are Inseparable. Your loss is irreparable. Inextricable confusion. An invulnerable hero. Impermeable cloth. An inexpugnable fortress. An inexorable tyrant. A navigable river. A ponderable gas.",
      "Repeat the adage. Send a message. He is in his dotage. Spinage is sometimes spelt spinach. Do not take umbrage at my remark. Rich foliage. A hemorrhage of the lungs. Encourage the diligent. A rich vintage. The steerage of a ship. The advantage of early rising. An average quantity. A safe passage. Prepay the postage. He shall have my suffrage.",
      "The annals of the city. An astral lamp. The fatal bridal. Vials of glass. A quintal of fish. A ribald scandal. The petals of the rose. Avoid that nasal delivery. An integral part. The primeval forest. A colossal cathedral. The emerald is green. The prodigal son. Several revisals. Tropical heat.",
      "Diluvial sand. An inimical memorial. Sidereal light. A suicidal policy. A hymeneal ode. A periodical attack. Ironical praise. Terrestrial scenes. An ordinance for the casting of more ordnance. A blatant calf. A garland for my hat. The sultan threatens vengeance. The tenant of the house. My quondam friend. The 10th inst., or instant, means the tenth of the present month. A vagrant giant. Peccant humors.",
      "The maintenance of a family. Your complaisance oppresses. The miscreant is now a suppliant. The adjutant is cognizant of his affairs. Is he conversant with mine? An irrelevant remark. A redundant word. The Metropolitan Hotel. The Mediterranean Sea. You owe me no allegiance. The defendant is he who defends; the plaintiff is he who complains. A puissant king. Discrepant testimony. Sweet marjoram. That I might soar to the empyrean! Antediluvian vestiges.",
      "Cut down the poplar. The orchard is full of caterpillars. Leeward is the part toward which the wind blows; windward, the part from which it blows. Rectilinear marks. A haggard dotard. A columnar form. A silver tankard. The granary is full. A conspiracy against the democracy. A mercenary emissary. Plenary indulgence. A veterinary doctor. Exemplary diplomacy. Sumptuary laws. A sedentary life. Capillary attraction. Your contumacy is too bad. A printed breviary.",
      "A residuary legatee. Look in the supplementary vocabulary. A cotemporary apothecary. Elementary studies. A supernumerary idler. An obituary notice. The populace menace the palace. Throw out the ballast. Purchase a cutlass. Heave at the windlass. A fracas in the street. Brown alias Jones. Commit no trespass. She has the erysipelas. They harass the enemy. Eleemosynary gifts.",
      "A judge of probate. The legate came with the prelate. A cup of chocolate. Name the predicate. The proximate cause. The penultimate syllable is the last but one. An inordinate glutton. The opiate made her sleep. A disconsolate widow."
    ]
  },
  "E in Unaccented Syllables": {
    title: "E in Unaccented Syllables",
    exercises: [
      "An aged and learned man. What is a bevel? He had a chisel. Goods and chattels. Loads of gravel. The grapnel did not hold. Col. is a contraction for colonel. Sound the timbrel. Fresh mackerel. Sleepy sentinels. Impannel the jury. Oriel windows. A novel in his satchel. The vessel sank. Disheveled hair. The lintel of the door. Doggerel verse The tailor stood at the tafferel. A dose of calomel.",
      "A shekel of silver. A hazel nut. Shovel the snow. They grovel before him. The brethren sang an anthem. A good omen. A kitchen garden. Solve the problem. A denizen of the city. Melt the bitumen.",
      "Quicken thy pace. The maiden's kitten. Bitten by a dog. A silken cord. Black as a raven's wing. A waxen doll. Eleven oxen. The mizzen mast. A swollen gum. Frozen cream. An even temper. Wooden shoes. How far have you ridden?",
      "Pungent odors. A trident has three prongs. The crescent moon. Reticence is concealment by silence. The garment fits. With deference your vehemence is out of place. What insolence! Explain the difference. He has a competence. Burn the frankincense. Total abstinence.",
      "Dissident voters. A diffident youth. An esculent plant is one good for food. The orient is the east. Excellent implements. A permanent president. The refluent tide. The air is redolent of spring. A salient fountain. A ligament for the bones.",
      "A contingent assessment. The respondent is he who answers. A vicegerent is an officer ruling in place of another. Inclement weather. Strong intrenchments. The fulfilment of a prophecy. The quintessence of an extract. Give her your condolence. A transference of troops.",
      "An advertisement in the newspaper. An incompetent superintendent The arbitrament of arms. I dispute his ascendency. A magniloquent address. Incipient disease. Shabby habiliments. Coincident views. The lake’s circumference. A recipient of his bounty. An ingredient of the mixture. Our antecedent correspondence. Pardon the inadvertence. The circumambient air. She played an accompaniment.",
      "Her teeth chatter. Filter the water. Bring a hammer to mend the ladder. A peddler of clocks. The leper was cured. He keeps a ledger. A cracker in the dipper. Bid the sharper scamper. Fodder the cattle. Sweet brier. Do not bicker over your beakers.",
      "A sutler in the army. Whisper low. Whither do you go? Tether the lamb. A sliver of wood. An iron baluster. A canister of tea. The gossamer floats. The glaciers of Switzerland. An exporter of cotton. The Counterfeit Detecter. Call the scavenger. A Turkish cimeter. A congener is a thing of like nature with another. The brook meanders. Sequester his estate. The barometer fell.",
      "Reconnoiter the field. The caliber of the gun. The fullness of bliss. A largess is a gift. The princess has a governess. A matchless actress. Harness the horse. A bootless errand. Grant him free ingress and egress. A mediocre performance. Seventy acres.",
      "The ‘g’ in gibbet has the sound ‘j.’ A bracelet for the wrist. Ferret him out. A camlet cloak. A bracket for the clock. A gusset in the shirt. The magnet attracts. A ticket for the concert. A pallet of straw. The baronet wore an epaulet. He ate an omelet. Put on the skillet."
    ]
  },
  "I in Unaccented Syllables": {
    title: "I in Unaccented Syllables",
    exercises: [
      "A cozy room. A lonely lily. The odor of tansy. Parsley in the pantry. Stand sentry. She has the quinsy. A motley fool. A plaguy fly. A stingy maid. Barley candy. Celery for dinner. A chancery suit. A cavalry officer. Look from the balcony. The present dynasty of France. A cursory review. Dimity curtains. Brevity is the soul of wit. A felly is the outward rim of a wheel: it was formerly written ‘felloe.’",
      "An effigy in ebony. An elderly person. The laity and the clergy. A. jewelry shop. Boil the hominy. Petty larceny. They swore fealty to the king. A homily or sermon. The crew mutiny. Prosody treats of verse. A youth of probity. I guarantee his solvency. Her sanity is really questioned. A large salary. Her eyes are fixed on vacancy. She is in a reverie. A warranty deed. Rely on my secrecy.",
      "A fit of apoplexy. A long controversy. A deed of ignominy. Orthoepy is the art of pronouncing words properly. A list of casualties. A mahogany box. A confectionery shop. The genealogy of the family. A valedictory address. A card manufactory. Taste of the macaroni. The Mississippi river. A spermaceti candle. Obesity is fatness. A soliloquy is a speech to one’s self. We lament his apostasy. Millinery goods.",
      "An eligible candidate. An accessible spot. An inexhaustible spring. Indelible ink. Man’s imprescriptible rights. Incontrovertible proofs. Incompatible with safety. Edible roots. An irresistible attack. All are fallible. An intrepid girl. A turgid style. The water is limpid and gelid. Rancid butter. His flesh is flaccid. The fruit is insipid.",
      "Arctic ice. A scenic display. A laconic saying. A volcano eruption. Didactic poetry. An ecstatic mood. An acrostic on Helen’s name. The eclectic is a chooser. I look to intrinsic, not extrinsic worth. A specific for your spasmodic cough. An aquatic bird. Mosaic work. Arsenic is poison. Organic remains. My prognostic proved true.",
      "A codicil to a will. A hostile missile. Febrile symptoms. A futile attempt. A mercantile library. Juvenile zeal. The bissextile day. A kitchen utensil. A volatile fluid. Shun evil. A weevil in the wheat. A beryl is a gem. Vaccine matter. Sound the tocsin. Bring out the engine. White as ermine. Rosin the bow. Resin exudes from the tree. A basin of water. My second cousin. A fresh raisin.",
      "A verbatim report. A clandestine marriage. Terrapin soup. She lost her moccasins. The harlequin turned a somerset. An alkaline powder. An alexandrine verse. Shun the libertine. A writ of replevin. Taste the metheglin. A ripe nectarine. Intestine wars. A genuine medicine. Honor the heroine.",
      "Anise seed. Finis is the Latin for end. Orris root. We have justice gratis. Put down the chalice. The summer solstice. Trellis work. Keep thy promise. Mortise the wood. The marquis sat on a dais. The earth turns on its axis. No basis for the slander. A crisis in our affairs. Charcoal dentifrice. Licorice root. A cicatrice is a scar. The bad apprentice had an accomplice. An interstice between the planks. A stroke of paralysis. My amanuensis writes as I dictate.",
      "The duelist was an atheist. An active horticulturist. A moralist and a humorist, but no egotist. A granite store. The preterit tense. A decrepit old man. No respite from toil. An exquisite tune. Our opposite neighbor. A massive rock. A large deficit. He made affidavit to the fact. The substantive or noun. The composite order Strike out the expletive. Not positive, but privative.",
      "An adhesive plaster. The possessive case. The superlative degree. An inquisitive girl. Exercise is a preventive of ill health. A provocative of appetite. An interrogative mark. I am apprehensive that the palliative draught will do no good. A retentive memory. Choose the alternative.",
      "Abolish the custom. Accomplish thy task. A skittish colt. The lining of a coat. A hireling crew. Boarding and lodging. An inkling of success. An apple dumpling. Free from blemish. Vanquish passion. Cherish the poor. Shoes and stockings. The callow nestling. A brilliant wedding. Without spot or blemish. They brandish their swords. Burnish the silver. A churlish refusal. Publish the news. A heap of rubbish. Let me replenish your plate. Varnish the panel."
    ]
  },
  "O in Unaccented Syllables": {
    title: "O in Unaccented Syllables",
    exercises: [
      "A chaldron of coal. Four quarts make a gallon. The last cion of the tree. A common felon. The talon of a bird. A tenon is the end of a timber fitted to another. The crime of arson. An ebon cloud. The act of a demon. A bad symptom. A ribbon for my apron. An iron flagon. Andirons for the hearth. A besom is a broom. A herd of bisons.",
      "Tough bacon. Blazon his worth. A crimson tint. My champion had no weapon. Halcyon days. The garrison holds out. A faded dandelion. Make a semicolon. A persimmon tree. She is at her orisons. Venison steaks. A benison on you! Caparison the steed. The plural of criterion is criteria. Abandon him to oblivion.",
      "A donor is one who gives. They punish the transgressor. Succor the needy. A bachelor of arts. The testator makes a will. Assessors were chosen. A dazzling meteor. An instructor of the poor. My tutor became a governor. The precursor of ruin. The inventor was no impostor. The collector of the port.",
      "The gladiator was slain. My ulterior views. The coadjutor of the ambassador. The conspirators fled. The administrator of the estate. An administratrix must be a female. My progenitors. A patent ventilator. The compositor sets type. My predecessor in office. Be an arbitrator between us. No instigator of mischief. The chief inquisitor.",
      "She spoke with acrimony. Alimony was granted to her by the court. Prefatory remarks. An inventory of my goods. Dilatory boys. Predatory attempts. A promissory note. This transitory life. The promontory juts into the sea. A repertory of good sayings. Did you put an Interrogatory? An ejaculatory remark. The depilatory spoiled my eyebrow. Lay it before the judicatory. A boiled turbot. They vote by ballot. A bundle of fagots. Honor the patriot. Bring out the chariot. A flower from the conservatory."
    ]
  },
  "U in Unaccented Syllables": {
    title: "U in Unaccented Syllables",
    exercises: [
      "A lustrum is a space of five years. Write in my album. A quorum not being present, the house adjourned. The plural of datum is data; of stratum, strata; of arcanum, arcana; of addendum, addenda. Pomatum for the hair. A state of delirium. A compendium of the news. From the museum we went to the lyceum.",
      "I made some memoranda in my memorandum book. The Colosseum yet stands. A correct census is a desideratum. Other desiderata are well-ventilated omnibuses and clean streets. A surplus crop. A crocus in bloom. The plural of stimulus is stimuli. A nucleus, or kernel. A prospectus of our plan. An asparagus bed. Asbestus is often spelt asbestos. Put no saleratus in the bread. A hiatus, or gap. The judge issued a mittimus. That was indeed a nonplus. A tremendous storm.",
      "A viscous fluid. A callous hand. A leprous complaint. A righteous judgment. A cumbrous bundle. The previous question. A libelous charge; villainous, viperous, and mischievous. Copious rains. An onerous task. Ligneous deposits.",
      "Disastrous shipwrecks. Nefarious frauds. Obstreperous hilarity. Preposterous conduct. A superfluous apology. Abstemious diet. Was the animal carnivorous or graminivorous? A pusillanimous assailant. An anomalous word. A hat impervious to rain. Gregarious animals."
    ]
  },
  "Sounds of F, G Hard, J, K": {
    title: "Sounds of F, G Hard, J, K",
    exercises: [
      "The nymph had a sylph-like form. My orphan nephew, shot a pheasant. A dying dolphin. A graphic description. A pharos or lighthouse. The sphinx's riddle. A pure atmosphere. Alphabet is from the first two letters of the Greek alphabet, alpha and beta. He drove a phaeton. A paraphrase of a poem. They formed a phalanx. The sophomore class. A strange metaphor. An improper diphthong. Heed the emphasis. I prophesy that you will fail. Your prophecy will fail.",
      "A phlegmatic temper. A mephitic odor. An amphibious animal. The apocryphal books. Say ‘this’ phenomenon, ‘these’ phenomena; never use the last word as in the singular number. We sound the ‘g’ in physiognomy. A phosphorescent light. Topography is a description of places. Typography is the art of printing. A diaphanous complexion. John’s book: — point to the apostrophe; the colon; the semicolon; the dash. A metamorphosis, or change of shape. A sarcophagus is a sort of stone coffin. What a catastrophe! Philology has reference to the study of languages. Phlebotomy is the art of blood-letting.",
      "Gimp is a kind of silk twist Give me the gimlet. He talks gibberish. The moon has a gibbous appearance. They giggle and gibber. An executive officer. An auxiliary verb. We sound the ‘h’ in exhort, exhaust, exhibit, exhilarate. An exotic plant. An exuberant growth. Your anxiety exaggerates the danger. An exorbitant price. The gills of a fish. An exordium, or introduction. Examine the target. Exempt from danger. Do not exasperate her.",
      "An agile dancer. A magic lantern. A litigious magistrate. Play on the flageolet. The legislature is in session. A carrier pigeon. Meteorological observations. Phrenological developments. A prodigious student. A sacrilegious theft. A courageous surgeon. A noble pageant. A tautological epithet. The zoological gardens. An advantageous arrangement. Dark as a dungeon. The trees bourgeon. The American aborigines. Indigenous to the soil.",
      "Pick up the conch. Chyle is formed in the stomach. Repeat the distich; also the hemistich. A bronchial complaint. The archives of the state. Catechise the architect. Tread on the chamomile. Strychnine is poison. An epoch in our history. Mastich or mastic. A schedule of my losses. Michaelmas day. A melancholy, lachrymose tragedy. A perfect chrysolite. A book on chemistry. The architrave of the edifice. A safe anchorage. An inchoate habit. The patriarch of the pentateuch. Technical words. Lichen from the rock. Mechlin lace. A fast schooner. The dentist administered chloroform.",
      "A parochial visit. The chronometer keeps good time. That date is an anachronism. The Archipelago is between Greece and Asia Minor. A chalybeate spring. Fair chirography. The hues of the chameleon. A characteristic act. Chalcedony is a silicious stone. A chimerical scheme. Chronology treats of the dates of events.",
      "A statuesque posture. A grotesque gesture. You pique her curiosity. A critique on her style. An aquiline nose. The Croton aqueduct. Liquidate the bill. A splendid equipage. An antique gem. The power of ubiquity. The requisite amount. The Chancellor of the Exchequer. A frivolous coquette. A piquant story. A mosquito-bar. Etiquette at a masquerade. Lacquer the box."
    ]
  },
  "Sounds of L, M, NG, S": {
    title: "Sounds of L, M, NG, S",
    exercises: [
      "The axle-tree broke. A buckle for my belt. The bubble burst. Mend the whiffle-tree of the vehicle. This gum is soluble. To manacle is to shackle. The beloved disciple. A particle of dust. A light in the binnacle. Why all this preamble? An edible fruit. My risible faculties. A tangible icicle. A barnacle on the ship. A flexible cane. Twelve is a multiple of three. We stood on the pinnacle. The nozzle of the bellows. A silver thimble. A supple figure. He tried to embezzle funds intrusted to him. The conventicle is in session.",
      "The pilot at the helm. The rhythm of the verse. She showed heroism in the act. His enthusiasm is much. like fanaticism. A cruel sarcasm. Macrocosm, the great or whole world, is in contradistinction to the microcosm, or little world of man. A paroxysm of grief. A solecism or impropriety. Polytheism is a belief in a plurality of gods. A pleonasm is a redundant expression in speaking or writing. Skepticism is doubt on any subject.",
      "A sprained ankle. Relinquish your hold. She spoke in anger. A syringa bush. A mushroom is a fungus. An ingot is a wedge of gold or silver. The younger delinquent. Shingle the roof. A concourse of people. I heard him distinctly. Elongate the belt. His conquest was complete. He came, he saw, he conquered. Do not languish in my absence. Linger not. My finger tingles. The clangor of arms.",
      "A docile learner. An acid plum. The facets of a prism. A placid temper. You gave a tacit consent. A new process of making paper. Here is a specimen. Unprecedented voracity. Try the velocipede. It moves with velocity. Her loquacity is tedious. You exacerbate her anger. His complicity was proved. I said mendicity, not mendacity. A municipal court. Elicit the truth. An illicit traffic. A sacerdotal robe. A doubtful precedent. A necessary expense. Why so taciturn? Macerate the berries. He will decimate the prisoners. An explicit demand. A medicinal quality. Participate in my joy. A recipe from the physician. Give publicity to the report.",
      "A duodecimo volume. I doubt its authenticity. A reciprocity treaty. An amusing miscellany. A postscript to the letter. A lady of transcendent beauty. My reminiscence accords with yours. I am dissatisfied with your dissensions. A dissipated and dissolute fellow. They disseminate mischief. Dissimilar tempers. Never dissemble. The dissenters met. Dissect is a dissyllable. Dissuade her from indulging in such dissensions. A dissonant tone. Dissever the tie. Dissimulation is a disgrace. He read a dissertation.",
      "The liquid will effervesce. A viscid mixture. His perspicacity led him to anticipate the result. An arbuscle, or dwarf tree. A visceral complaint. Rely on my acquiescence. His proboscis formed a singular excrescence. Ascetic habits. May his convalescence be speedy. Adolescence is the age between childhood and manhood."
    ]
  },
  "The Sound of SH in Shine": {
    title: "The Sound of SH in Shine",
    exercises: [
      "The marshal's truncheon. A stanchion or prop. On Lake Champlain. The charlatan came in a chaise. The chevalier has shaved off his mustache. The aeronaut came down in a parachute. An avalanche swept away the hut. The marchioness treated him with nonchalance. The chandelier fell. Ingenious machines. A gracious smile. A spacious pretence. I use no coercion. Her knowledge seems like prescience. A luscious grape. Pay me in specie. She pines with chagrin. A lobster is crustaceous; an oyster, testaceous. Champagne wine. By a champaign country, we mean an open, level country.",
      "A veracious narrative. An argillaceous or clayey soil. Farinaceous food. Efficacious medicine. A contumacious boy. A pertinacious blunderer. A voracious fish. A special verdict. A precious baby. An auspicious beginning. A capricious and superficial little lady. An efficient teacher. A sufficient suspicion. A silicious or flinty earth. Her proficiency amazes. A politician, but no rhetorician. An officious, avaricious man. A ready arithmetician. Reverence the Omniscient One.",
      "A balloon ascension. An unjust aspersion. Depression of spirits. A long procession. False professions. In possession of property. A percussion cap. An animadversion on his transgression. Quick of apprehension. The queen’s condescension. An excursion into the country. A succession of losses. The secession of our allies must lead to a suspension of hostilities. A nauseous draught. Is it cassia or cinnamon? A new version. An important mission. A fit of passion.",
      "The nuptial ceremony. A fractious child. A partial report A captious critic. The ratio of increase. A sailor’s rations. The convention met. They showed their credentials. A portion of the nation. The doctor has many patients. An infectious disease. A facetious youth. The frustration of my plans. You drive me to distraction. A description of the scene. Expatiate on it. Divide 12 by 8, and 4 is the quotient. The consumption of fuel. Inertia is want of action. A solid foundation. My morning ablution. The abduction of the maid.",
      "Apt quotations. An inspection of troops. Inscriptions on tombstones. Preemption rights. My heart’s pulsation. Prudential policy. Obstructions in the road. Negotiate for the plantation. Filled to repletion. Rotation in office. A sententious style. A sum in subtraction. Give him your subscription. Shun temptations. A vexatious taxation. Stagnation in business. A reaction must be near.",
      "The adaptation of the parts. An altercation among the students. A dilatation of the eyes. The confiscation of his property. A severe flagellation. Free from molestation. She holds him in detestation. He sought an assignation. Defamation of character. An aberration from the right. Numerous emendations. He accused her by implication. What is his appellation? A commutation of his sentence. The declination of the star. A ruinous conflagration.",
      "The rarefaction of the air. He was fluent in his protestations. A presidential proclamation. His conduct is without palliation. The oscillation of the pendulum. Let there be good ventilation. A reverential supplication. In vaccination the first ‘c’ has the sound of ‘k’, the second the sound of ‘s’ in ‘so.’ A predilection in her favor. My recollection fails. The preparation for winter. The prorogation of parliament. A specimen of alliteration. The Colonization Society. Give me your cooperation. A corroboration of the fact. Speak without circumlocution or amplification.",
      "A minister plenipotentiary. A work of supererogation. Procrastination is the thief of time. The circumnavigation of the globe. Never resort to tergiversation or evasion. A reciprocation of benefits. I met her in my peregrinations. The multiplication table. A mark of interrogation. The realization of my hopes. Study to be accurate in your pronunciation.",
      "A new edition. A rational ambition. Flagitious conduct. Munitions of war. A partition of the spoils. A partition of wood. He made deposition to the fact. An ebullition of anger. The attrition of the stone has worn a hole. The ignition of the chips. A dishonest coalition. An initiatory explanation. Solstitial weather. A pressure in the money market. The cynosure of all eyes. A fissure in the wall. A policy of insurance."
    ]
  },
  "Sounds of T, TH, W, Y, Z, ZH": {
    title: "Sounds of T, TH, W, Y, Z, ZH",
    exercises: [
      "She laughed while he danced. He missed the path. Our breaths froze. The youths had withes. She is betrothed. He took ether. The surveyor had a theodolite. The misanthrope grumbled. Overcome your antipathy. The plural of antithesis is antitheses. A probable theory. The acanthus tree.",
      "A heavy burthen. The baths are ready. Our paths are different. A lithe figure. Oaths are superfluous. Do not mouth your words. She makes mouths. Prithee, go thither with thy laths.",
      "Moral suasion. The custom has fallen into desuetude. He speaks with suavity. A soothing unguent. A sanguine disposition. Filial respect The premier has resigned. The Saviour of the world. A valiant vizier. He dropped a poniard. A folio volume. A dahlia in bloom. Stand by the Union! Noxious vapors.",
      "Excuse my companion's behavior. The battalion consisted of ten companies. A congestion of the lungs. An emollient application. An ebullient enthusiasm. An espalier is a tree on a frame. A supercilious glance. A peculiar opinion. A fusion of parties. A violent transition.",
      "The foreclosure of a mortgage. A collision of locomotives. A contusion on the head. Try a cold affusion. Do you suspect him of collusion? Why this intrusion? Misprision of treason."
    ]
  },
  "Silent Letters": {
    title: "Silent Letters",
    exercises: [
      "The jamb of the door. A plumb line. The redoubt was taken. The czar's palace. They will indict the victualer. A schism in the church. A poignant malady. Condign punishment Impugn the foreign witness. An apothegm or maxim. A paradigm or modeL The Grand Seignior. The hand of a dial is a gnomon. Be resigned. A schismatic intruder. The campaign is finished. An ambitious sovereign. The captain is absent on a furlough. A sign of rain. A benign disposition. A gnarled oak. He could not assign a reason for his conduct.",
      "She is an heiress. Give the knave the knout. An honest burgher. A plate of gherkins. Rhubarb for the asthma. The isthmus of Suez. A knurly tree. Smoked salmon. She suffers from a catarrh. Bestow alms. A humble exhibition. The falcon flew. The calker let go the hawser. A wooded knoll. A knuckle of veal. A Psalter is a book of the Psalms. Balk his malice. A pretender to palmistry. My palmy days. The old folks at home. An almond tree. A qualm of conscience. A humorous rhapsody. The psalmody of the psalmist. A soldier's knapsack. Naphtha is bituminous. A balmy air. A figure like a rhomb.",
      "The lim’ner sat down to limn. They sang a hymn. The solemn autumn time. A column of infantry. In condemning his attempt, recall the provocation. A mnemonic device. A pseudo viscount. Apropos to that, Where is his demesne? A ptisan for the sick man. An empty coach. A sumptuous banquet Preserved raspberries. I paid the bill, but received no receipt.",
      "A bustle at the castle. It does not rain; it mistles. Often at Christmas we used to wrestle. The beloved apostle. The mistletoe bough. A mortgage on the land. My colleague was wroth. A prologue is spoken before, and an epilogue after, a play. Fasten the window. Harangue the crowd. Answer the demagogue. The ten commandments are the decalogue. A sprained wrist. Opaque glass. A catalogue of the goods. An amusing dialogue. An apologue or fable. A fugue is a repetition of parts in a musical composition. He was hustled by the mob. A pestle and mortar. Leaves rustle. A trestle for the bridge. She looked awry. He was wroth at her misdemeanor. They wrought well, and finished their labors soon. Words Sounded Alike",
      "He adds it up. An adz for cutting. Home-brewed ale. What can ail him? Pure air. Heir to the estate. Whene'er I am late at school, I regret it. Think twice ere you do it. The shoemaker's awl. All the world. The aisle of the church. An isle in the lake. I'll go kneel at the altar. Alter thy course. The ship's anchor. An anker of wine. Noah's ark. The arc of a circle. A steep ascent. Ask his assent. He ate eight muffins. An auger for boring. An augur's predictions. For aught I know you ought to go.",
      "A bald head. He bawled for his ball. A bale of goods. He gave bail. Bail out the boat. Green baize. The poet's bays. A bear's skin is better than a bare skin, this cold weather. A base attack. He sings bass. Barm is yeast. A soothing balm. I bade the bad boy wait. The Bay of Biscay. The Bey of Tunis. Be busy as a bee. Beat the carpet. Sugar from beets. The sea beach. A beech tree. Spruce beer. A body on a bier.",
      "Ring the bell. The belle of the ball-room. A bin for wine. Where have you been? My birth-day. No vacant berth in the cabin. The fish will not bite. The bight of a rope. A blue sky. The wind blew. She is bolder than he. A bowlder of granite. I bowled in the alley an hour. A bold man. Bore a hole. A wild boar. Plane the board. Board and lodging. They bored two holes. A bowl of milk. The boll of a plant. Bole is a kind of clay.",
      "He was borne away. Gone to his long boom. Bow as you enter. A leafy bough. A bow and arrow. A spruce beau. Braid thy hair. The ass brayed. A cane brake. Break the stick. A brakeman was killed. A breach in a wall. Breech the lad. Brown bread. A well-bred girl. Broach the topic. Wear this brooch. He brews porter. A bad bruise. A brute beast. A bruit or rumor. A butt for ridicule. A butt of wine. But why? Buy this book. By and by. A ripe berry. Bury the dead.",
      "A calendar month. Calender the cloth. Call the girl with a caul on her head. The canons of the church, not cannons for firing. The speakers are to canvass the topic in a canvas tent. A cask of beer. A plumed casque for the warrior's head. He has lost caste. Be not cast down. We cede our claims. Sow the good seed. Ceil the roof. Seal the letter. Seel the eyes of the hawk. A painted ceiling. Sealing-wax. A dark cell. We buy and sell. The seller of stolen goods lived in a cellar.",
      "He sent for his ten per cent dividend. The scent of the rose. Cere the cork. A sere leaf. Sear the wound. He calls himself a seer. Congress is in session. The Indians made a cession of land. A chaste design. Dogs chased a wolf. Cite the passage. A site for a cottage. Her eyesight fails. The cat’s claws. A clause in a will. A pleasant clime. Climb the mast. Coarse cloth. Cheap, of course.",
      "A kernel of corn for Colonel B—. A full complement of men. My compliments to your aunt. A musical chord. A cord of wood. Whip cord. The core of an apple. A military corps. My coat is in the sheepcote. Cousin, you cannot cozen me. Why creak the door? He fished in the creek. The crews returned from a long cruise. Cruel Susan, put down your crewel. A cygnet or young swan. A signet ring.",
      "Deign, noble Dane, to hear us. No Dey of Algiers reigns to-day. Come, dear, and see the deer. Wet with dew. My note falls due. A dire event. A shawl for the dyer. The doe runs. Bake the dough. Cash the draft. A draught horse. A draught of ale. A dram drinker. Eight drams make an ounce. A drachm was a Grecian silver coin. A cow of a dun color. He ran from a dun. You have done it. Dust to dust. Dost thou dare? Earn your bread. An urn for tea. Must she die? Dye the cloth. A ewe is a female sheep.",
      "I would fain know why you feign illness. The fane at Eph’esus. Did she faint? All a feint! Fair ladies, pay your fare. It was my fate to be ill the day of the fete. To jump ten feet is a feat indeed. Filter the river water. A love philter. Philip, why that fillip with your fingers? A flea made me flee. Find the man who was fined for smoking.",
      "The bird flew up the flue. A barrel of flour. A fragrant flower. Fort Ticonderoga. Dancing is not my forte. A fourth time I went forth. A foul deed. A flit fowl. A strange phrase. Frays in the street. Hang thy fur cloak on the fir tree. Freeze the cream. Frieze the cloth. An ornamented frieze. My dog has two hind feet, and fore feet besides; yet he has only four feet. He was wrapt in furs. A hill overgrown with furze.",
      "Throw down thy gage. Gauge the cask. Bitter as gall. Ancient GauL He walked on with a peculiar gait to the great gate. An iron grate. A nutmeg grater. A greater than Caesar. A grease spot. The isles of Greece. How Laura has grown! I heard a groan. I guessed it was from the strange guest. Grieves he for aught? Greaves are for the legs.",
      "The hale man said to the hail storm, All hail! They met in the hall. Haul in the rope. Auburn hair. Hounds chase the hare. The hunter shot the hart through the heart. Hey, boys! what say you to getting in the hay — eh? She hurt her heel, and asked him to heal it. Let them come here and hear me sing the hymn. I heard the lowing of a herd of cattle. Master Hugh, you may hew down that yew tree of the dark hue. Hide and seek. She hied to school.",
      "Hie along. High tide. I know those who will hire me at higher wages. A hoard of money. A horde of savages. Ho! ye that hoe in the field. The hoes for hoeing are in the bam. Here are the hose for your feet. I was a whole day digging that hole. The holy man must be wholly sincere. Drive hoop. Come with a whoop, and come with a call. Our school will not begin for an hour. Huzza! cried the hussar.",
      "I’ll wait in the inn. The Isle of Man. They will indict him for forgery. Take your pen; I will indite. She will jam her finger. Point out the jamb of a fireplace. Be just, and fear not. A joust, or mock fight. The knave tried to kill him in a lime-kiln. What is the nave of a wheel? Of a church? Knead the bread. A time of need. He walks in-kneed. He inveighed against the men who are going to invade Ireland. I knew the coat was not new. He knows he has a nose. Dark as night was the steed of the knight. Why not untie the knot? He laid down his gun, and began to lade the ship. He then lay down to sleep. He had lain an hour in the lane. The lapse of time. The cat laps milk. Lax morals. He lacks strength. We strolled over the lea. A lee shore. A leech in a vial. Leach the ashes.",
      "Pluck the leaf. Would you as lief walk? Stop the leak. John ate a leek. He led me through a lead mine. Lean on my arm. I have a lien on his estate. Lessen the lines of my lesson. A levy of troops. I was present at her levee. I hate a lie. Of lye we make soap. The limb of a tree. Limn it on canvas. The eyes of a lynx. The links of a chain. A heavy load. A lode, or metallic vein. The cows lowed. A lodestone. A lone woman. A state loan. Lock the door. We sailed on a Scottish loch. Lo! low lies the hero! The music of the lyre. Shame on the liar!",
      "The maid made a mistake. The male passenger sent his letter by mail. The main chance. A lion's mane. The lord of the manor has a gracious manner. A man of mark. Letters of marque. United States Marshal. Martial music. Maize is Indian com. Lost in a maze. Taste the mead. The meed of thy exertions. What mean you? A lofty mien. Carve the meat. When shall we meet again? With what measure ye mete. While a minor he labored as a miner in Iowa. I missed you in the mist.",
      "You might give me a mite. A mighty man. Mity cheese. A mown field. A loud moan. A mote in the eye. A moat around a castle. The patient raised a quantity of mucus. Mucous matter. The cat mews. Muse on his fate. Did the horse neigh? Nay! I gave him a nice bit of gneiss. A nit in the hair. Knit the purse. A yelled nun. None are left. Iron ore. An oar for the boat. O'er the lake we go. He won one prize. He wrote an ode. He owed me money. O, did he owe you? Oh, despair!",
      "Pale features. Paul, fill the pail. A sable pall. Panes of glass. Pains in the head. She tried to pare a pear with a pair of scissors. The dog's paws. Pause, and reflect. Peace, ye brawlers! A piece of bread. The highest peak. A feeling of pique. A peal of thunder. Orange peel. Pearl breast-pins. The streams purl. The noble peer built a pier. A lead pencil. A pensile garden. A plain statement. My carpenter's plane.",
      "A plate of fruit. A metal plate. Plait my ruff. Please attend. The Court of Common Pleas. A ripe plum. Plumb the wall. A barber's pole. Poll the votes. Pour out the tea. The pores of the skin. Safe in port. The Sublime Porte. Pray to be good. A bird of prey. Pumice stone. A pomace of apples. California quartz. Eight quarts of oil. A quire of paper. The choir sang.",
      "On the rack. From wrack we make kelp. Rain fell. Rein in the colt. The Reign of Terror. Raze the building. Raise the fallen. The sun’s rays. A rap for your knuckles. Wrap thy old cloak about thee. He read from a book with red covers. Can you read? A reed shaken by the wind. The wreck of a ship. What reck I? They reek with perspiration. Why wreak your vengeance on me? Rest here. Wrest the bowie knife from his clutch. Succor the wretch. The medicine makes him retch. A rice field. A rise in the price of land. The rigger treated me with too much rigor. Windows covered with rime. A rhyme for it is time. Ring the bell. Wring out the cloth.",
      "Do right, come what may. Write to the shipwright The sacrament is a holy rite. We rowed up the river, while they rode along the road. A row on the pond. The roe of a smelt. I wrote a page. I learnt by rote. The enemy were put to rout. What route do you take? Under the rose. Six rows of elms. A rude boy. The holy rood. Bough weather. A clean ruff. They rung the bells, and wrung out the towels. A wry face. Rye bread.",
      "For sale. Ships under sail. I have seen the fish that were caught in a seine. A fair scene. You can see the sea. The ships seem near. A coarse seam. Seize the present. On the high seas. She sees a star. A Russian serf. A boat swamped in the surf. A coat of serge. The roarings of the surge. Sheer nonsense. Shear the sheep. A pair of shears. Sheers for raising weights. The sun shone. A picture was shown.",
      "She sighed to quit my side. A good sign. The sine of an arc. A thick skull. Scull the boat. A drive in a sleigh. Though He slay me, yet will I trust Him. A loose sleeve. Sleave the threads. They slew the fugitive. Slue the sleigh round. A sleight of hand. A slight illness. At a slow rate. Sloe berries. The sole of the foot. The immortal soul. Sow the seed. Is it so you sew my sleeve? Eagles soar. A sore lip. She had a staid demeanor. He stayed some time.",
      "Why stare as you go up stairs? A beef steak. Drive the stake in the earth. I have all at stake. Step on. The steppes of Asia are like our prairies. Steal not. A steel chain. Climb the stile. A style of writing. The stoop of a house. Stoop lower. A stoup of wine. A narrow strait. A straight path. Straighten the lead pipe. In straitened circumstances. Do it straightway. A sweet peach. One of the prince's suite. Succor the suffering. Pull up those suckers. Some boys would do that sum mentally. Mr. Gay’s eldest son was sun-struck.",
      "A tale-bearer. A fox's tail. Tear up the tares. The dog tax. Tacks for the carpet He drives a team. These ponds teem with fish. A tier of boxes. She shed tears. There they left their horses. He threw the cat through the window. A throe of pain. Throw a quoit. He throws it well. Throes of anguish. The king sat on his throne. They wrestled, and he was thrown. High tide. They tied his hands.",
      "It is time to pluck the thyme. They towed the boat. Toads are harmless. He hurt his toe. A load of tow. They told the sexton, and he tolled the bell. Two o'clock. Too late. They toled along the fish by throwing bait. A desert tract They tracked him through the snow. A tea tray. The trey of clubs. A shady vale. She wears a veil (or vail). See the veins in her neck. A vain attempt. The vane on the steeple.",
      "Boys wade in the stream. They weighed the gold. A wail of sorrow. Cloth woven with a wale. The creaking wain. The moon is on the wane. Wait a moment. The weight of an ox. Waste not, want not. A slim waist. Hard ware. Why wear that cap? The ocean wave. Waive the question. This is the way. Weigh the meat Once a week. A weak woman. She will wean the infant, I ween. Fine weather. The bell-wether of the flock. Would you go? A cord of wood."
    ]
  },
  "Words Nearly Similar in Sound": {
    title: "Words Nearly Similar in Sound",
    exercises: [
      "Carry alms, not arms. She allowed him to speak aloud. The baron’s barren acres. The bridal feast. A bridle for the horse. Calk the ship's sides, and put a cork in the jug. I said a candied, not a candid tongue. A capital crime was committed in the capitol. A yellow carrot, but not a carat of gold. In his choler he threw off his collar. Currant wine. Current news. A councilor is a member of a council. Call the counselor at law.",
      "A dessert spoon. Never desert a friend. A grisly bear. Gristly meat. Formerly you addressed me less formally. Expressive lineaments. Liniments for rheumatism. A hair mattress. A chemist’s matrass. Meddle not with my medal. An illuminated missal. He threw a missile. Ottar of roses. They caught an otter.",
      "A pedal for the piano-forte. A pendant at the ear. A pendent rock. She pitied me, I was so pitted by the small-pox. A stationary cart. The stationer sells stationery. Subtile air. A subtle rascal. Religious sects. The glory of her sex. The prophet found neither credit nor profit. The pistil of a flower. A double-barreled pistol. He sought a sort of glue. A man without principle. The principal man of the town. The peddlers peddle wooden wares. A bass viol. A vial of medicine. Her travail is ended. We will travel together."
    ]
  },
  "Words Differing in Accent": {
    title: "Words Differing in Accent",
    exercises: [
      "Those who absent themselves are absent An abstract noun. He abstracted the money. A wrong accent. Accent the word. Put an affix to the word. Affix your signature. An attribute of deity. Attribute it to the right cause. A charming concert. Concert your plans. A strong contrast Contrast this with that. A convert is one converted. A convict is one convicted of a crime. A concrete mass. Concrete the particles. Pleasant converse. Let us converse.",
      "Do not desert us in the desert. An escort of cavalry. They escorted the governor. His first essay. He essayed in vain. An export duty. They export cotton. An extract from Addison. Extract the tooth. The town is in a ferment. Beer ferments. A frequent occurrence. He frequents bad society. A sweet perfume. It perfumes the air. A base insult. Insult no one. Do you object? A wretched object. He presents some presents. The project failed. He will project another. I protest against it. Your protest will not be heeded. Let me premise. Your premises are fallacious.",
      "A rebel is one who rebels. Record the date. Search the records. A basket for refuse paper. Refuse the gift. A subject for a poem. Man subjects animals to his use. A survey of land. Survey the field. He suffered torments. Why torment her? A transport ship. You transport me by the good news.",
      "He heeds not her abuse. Do not abuse him. A close atmosphere. Close the door. A diffuse style. The furnace diffuses warmth. A poor excuse. Excuse her. A pot of grease. Grease the wheels. They effected a compromise. Compromise the matter. The invalid's agreement was invalid. A gallant ship. He was quite gallant to the ladies. The month of August. An august presence."
    ]
  },
  "Rules for Spelling": {
    title: "Rules for Spelling",
    exercises: [
      "There are no eggs in the inn. A full class. A curable disease. Salable property. She is blamable. A movable rock. Changeable silk. A serviceable cloak. Peaceable neighbors. The boy received a swingeing for swinging without leave. The cook, while singeing the turkey, is singing. The dyer is dyeing my mantilla. The cat is eying a blackbird.",
      "The blacksmith is shoeing a horse. Stop frolicking, boys! He is hoeing potatoes. She is tying a knot. He is lying down. A debatable subject. An irreversible decision. Improvable land. Your horse is not comparable to mine. A desirable lot. An excusable retreat. An indisputable fact. A manageable colt. An agreeable person. A noticeable woman.",
      "Bathing in the river. She is teasing him to go. He is cringing to the rich man. We are chasing a fan. She is wincing at his rebuke. A juicy peach. He is lying on the sofa. They are frolicking in the bam. The doctor is physicking the patient. In pursuance of your request, I will go. A universal festival.",
      "A sudden movement. Illustrious achievements. That which is awful inspires awe. You argue the point, but your arguments are old. A convenient abridgment. Accept my acknowledgment for your judgment. A graceless youth. A peaceful assembly. A rueful glance. His management failed. Excuse my hoarseness. My engagements forbid.",
      "She behaved shyly. He certified to the fact. She magnifies my merits. The dryness of the atmosphere. Thou prophesiest. The clumsiest of the boys. A fanciful project. A remediless complaint. Be in readiness. Busily employed. Which horse is the speedier? A pitiless master. He justified his conduct He defieth his oppressors. Thou repliest not.",
      "I mislaid the paper-knife. She laid her hand on his head. He lay down gayly on the floor. Why did he lie down? He had not lain long when he rose. He delayed his departure. He slays his thousands. The Daily Gazette. Irrepressible gayety. The destroyer of her peace.",
      "Unwholesome gases. A waxen image. They acquitted the prisoner. He is blotting his book. They submitted at once. He annulled the contract. They controlled his choice. He demurred. He was impelled to do it. They are extolling his conduct. They are controlling the affair. The drummer was a swimmer. The beggar was an abettor of the crime. A shrubby growth. The bigger of the two. The fattest of the three.",
      "He confessed his connivance. They lolled in their chairs. He engrossed the bill. I repressed my tears. You transgressed the bounds. He spilled the soup. They are toiling at the oars. They are balloting for a speaker. She enveloped me with her shawl. They paralleled the act. You are forfeiting my esteem. A fattened ox. They profited by my ignorance. He riveted the chains. A tedious visitor. A devout worshiper. He galloped fast. My preference is for her. Its excellence is plain. Lightly appareled. The birds caroled. He libeled the doctor. Disheveled hair. An enameled watch. They shoveled the snow. The jeweler gamboled. The gambler gambled. The traveler periled his life. They tunneled the hill, and leveled the mound.",
      "A successful practitioner. Fulfill your promise. Instill good thoughts. A skillful mechanic. She ran heedlessly to the cars, and her carelessness was punished. The smallness of the amount. A willful temper. She blundered grossly.",
      "Take three spoonfuls of the liquid. Shake the door-mat Two handfuls of com. Until we meet, farewell. Your welfare shall be my concern. So it has been always, albeit you are altogether bad withal. Their Excellencies, the Governors, had many excellences of character.",
      "Roses for the Misses B —. Light the torches. Six cases of preserves. Six gills of vinegar. The gills of a fish. Malapert girls. Gooseberry pies. Six glasses of syllabub. Ten demijohns of vitriol. Ripe musk-melons. Silver salt-cellars. The nightingales sang. The purlieus of the state-house. Numbers were massacred. Memoirs of Napoleon. Battledoors and shuttlecocks. Worsted stockings. Animalcules in the water. The thresholds of the doors.",
      "The calicoes were cheap. Sweet potatoes. The bravos had stilettoes. A poem in ten cantos. Two small duodecimos. Receive them as mementos of my affection. The General’s manifestos. Beautiful cameos. Volcanoes in a state of eruption. The porticos were spacious. Prolonged echoes. The manifestos were read to the armies.",
      "Daisies in bloom. She defies his calumnies. The comedies were hissed. Prodigies of valor. They had subsidies from the enemy. The chimneys were blown down. Volleys of musketry. Lackeys were in waiting. The penalties of his offences. Parodies of the poem. Bad tendencies. She is in ecstasies. The forgeries were discovered. Task all your energies. They sat in the galleries. The deputies are here. The poppies are faded. The rubies sparkle. Parties are divided. The jellies are too soft. The ponies ran. Our allies refuse further supplies. The chimneys fell.",
      "Put the loaves on the shelves. With knives we cut the apples in halves. The thieves fled. The beeves were in good condition. Ten handkerchiefs. The Southern gulfs. What is the singular of staves? The ships are at the wharf. The dwarfs were exhibited. Your reproof pass unheeded. Muffs for the ladies. The flagstaffs were pulled down. Will their strifes never end?"
    ]
  },
  "Illustrating the Punctuation-Marks, &c.": {
    title: "Illustrating the Punctuation-Marks, &c.",
    exercises: [
      "Notwithstanding all his diligence, the general could not reach the city before the enemy. Lend, lend your wings. Alfred the Great was a brave, pious, and patriotic prince. Milton the poet seemed a different being from Milton the politician.",
      "God, who made the world, dwelleth not in temples made with hands. I sold my house, which was not large, for a moderate sum. Napoleon, whose impatient spirit could brook no obstacle, was highly indignant at this concession.",
      "He insisted upon a condition which ultimately proved fatal to the arrangement. I met the person whom you introduced. Every teacher must love a boy who is attentive and docile. Study, I beseech you, to store your mind with the exquisite learning of former ages.",
      "Boast not my dear friends, of to-morrow. Antonio, light my lamp. I am obliged to you, ladies, for your kindness. The impossibility of extricating himself and his comrades from their dangerous position, drove the commander to desperation. Peace of mind being secured, we may smile at misfortune.",
      "Wisdom, power, and goodness, are the attributes of the Deity. They passed their time in drawing, working, reading, and playing. He Is a worthy, liberal, and benevolent man. I came, saw, and conquered. Two, four, six, eight, and ten, are even numbers. He behaved rashly and violently. They rushed into the streets singing, shouting, and screaming violently. I was received civilly and hospitably.",
      "Young and old, rich and poor, wise and ignorant, will appear before the judgment-seat. The captain drew his sword, encouraged his men, and led them on to the conflict. Scipio was called the sword, and Fabius the shield, of Rome. Fill thy heart with goodness, and thou wilt find that the world is full of good. As virtue is its own reward, so vice is its own punishment.",
      "The slaughter was dreadful; a few escaped by subterraneous passages, and made good their flight into the country; others retired into the citadel, which was soon obliged to surrender at discretion, and was razed to the ground; but by far the greater number perished in the town, under the sword of an irritated and relentless victor.",
      "“Augustus well knew,” says the historian, “that mankind are governed by names; and that they will, in general, submit to real slavery, if they are told that they are in the enjoyment of freedom.” Straws swim on the surface; but pearls lie at the bottom. Yesterday the sky was beautifully bright; to-day, all is dark and dreary. Semiramis built Babylon; Dido, Carthage; Romulus, Rome.",
      "Patrick Henry commenced by saying, “It is natural to man to indulge in the illusions of hope.” Good and evil are the lot of man. No one must expect either happiness or misery unmixed. Virtue is too lovely and useful to be immured in a cell: the world is her sphere of action. Brutus’s speech over Caesar’s body begins thus: “Friends, Romans, countrymen, lend me your ears.”",
      "Collins’s Ode on the Passions contains this line: “Through glades and glooms the mingled measure stole.”",
      "Avoid affectation: it is a contemptible weakness. The air was sweet and plaintive; and the words, literally translated, were these: “The winds roared and the rains fell, when the poor white man, faint and weary, came and sat under our tree.” The Rev. Dr. Paley",
      "What do you want? Will you come with me? What o’clock is it! Where is Elizabeth? Here! dear mother. Hush! you will wake the child.",
      "Where have you been all day? Bravo! well done! How beautiful is night! Daughter of Faith! awake! arise! illume the dread unknown, the chaos of the tomb. Alas! poor Yorick.",
      "Consider (and may the consideration sink deep into your hearts!) the fatal consequences of a wicked life. A coalition took place. Cooperate with us in the undertaking. Should that time come — but I will not descend to threats. Newton, Franklin, Washington, Napoleon, — different as they were in many respects, — were all renowned as hard workers. I could not stop my hiccough."
    ]
  },
  "Writing The Possessive Case": {
    title: "Writing The Possessive Case",
    exercises: [
      "I picked up a lady’s fan, and have asked several ladies if it belonged to any one of them. A person’s manners are apt to influence his fortune. The doctor's horse was got ready at five minutes’ notice. I admired the workman’s dexterity. Our country's welfare. Deer's flesh is called venison. William’s book is torn. “What is everybody’s business is nobody’s business. Wisdom’s voice.",
      "Cows’ horns are made into combs. The men’s hats were hanging against the wall. Ladies’ and gentlemen’s shoes for sale here. The tiger’s skin is striped, the leopard’s spotted. The ship’s rudder was knocked to pieces. Seven weeks’ sickness has made poor Oliver quite thin. The boys’ pens were mixed up with the girls’ knitting-needles. I hear the sound of horses’ feet A horse’s speed. The swan’s neck is long and graceful. Ducks’ feet are webbed.",
      "Doesn’t this fine weather make one happy? That’s right: you’ve done this exercise well. There weren’t more than ten persons present. We’ve been at play. You’re welcome to go, if your wishes so incline you. Wouldn’t you like to see the new cow? We’d rather see her another time. I couldn’t give the poor man money, for I hadn’t any. They’re all gone. It was early dawn. Where’er you go, don’t forget me. They’ll jump o’er the brook."
    ]
  },
  "On Derivatives": {
    title: "On Derivatives",
    exercises: [
      "Walk in the shade. The shadow of the stick. You breakfast when you break your fast. A wild beast's cave. A robber's cavern. Vanity is being vain. The vale of Tempe. The valley of the Shadow of Death. They who continue to prate, prattle. A humane man is known for his humanity. A great nation. National customs. That is natural which is according to nature. A pale blue. A pallid hue. A sane mind. An act of insanity. A vain attempt. A proof of vanity. Slake your thirst. He slackened his pace. A game at cricket. To gamble at cards.",
      "We are pleased with what is pleasant. A man of zeal is zealous. Those who cleanse make clean. A deep ditch. The depth of the hole. A shepherd tends sheep. A dearth of provisions. Things are dear. A serene sky. The serenity of the mind. A secretary keeps secrets. A shield shelters us from danger. His wound is healed. In good health. What is breathed forth is the breath. Those who steal do it by stealth.",
      "A wide ditch. The width of the room. Bite the apple. A bit of bread. A wise man. Solomon’s wisdom. A criminal has committed a crime. A vine in the vineyard. A lineal descent. In a right line. Five times ten are fifty. Behind the house. Do not hinder me. Every thing grows wild in a wilderness. Divinity is the state of being divine. They conspired against him, and a conspiracy was formed. When coals are ignited, they are in a state of ignition. Decline a noun of the first declension. Mimes are men who mimic.",
      "A new mode. The model of a statue. Knowledge is what we know. Colliers carry coals. The forehead is the fore part of the head. Holidays ware formerly kept holy. He imports corn. An important fact. A pig in a poke. Put it in your pocket. Black cloth. Clothed in flannel. A deep hole. A hollow tube. Tonic medicines give tone to the nerves. Conic sections are the cuttings of cones. I am sorry you have a sore throat. The young of a goose are goslings. A full moon once a month."
    ]
  }
};
